<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
            <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

            </v-breadcrumbs>
            </div>
        </v-row><br><br><br />

    <v-card class="card">
      <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
      <v-card-title class="heading justify-center">Title Report</v-card-title>
      <div class="">
        <div class="add-section">
          <div class="d-flex">
            <v-col col="sm-3">
              <label class="add-text">Series</label>
              <v-autocomplete v-model="series" placeholder="Select Series" :items="series_data" item-text="name"
                item-value="id" outlined dense class="text" :rules="[rules.required]" clearable required></v-autocomplete>
            </v-col>
            <v-col col="sm-3">
              <label class="add-text">Department</label>
              <v-autocomplete v-model="department" placeholder="Select Department" :items="dept_list" item-text="name"
                item-value="id" outlined dense class="text" :rules="[rules.required]" clearable required></v-autocomplete>
            </v-col>
            <v-col col="sm-3">
              <label>Start Date</label>

              <v-menu v-model="fromDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                full-width max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field class="text" v-model="startDate" placeholder="Select Start Date" append-icon="event"
                    persistent-hint dense readonly outlined v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="startDate" no-title @input="fromDateMenu = false"></v-date-picker>
              </v-menu>
            </v-col>

            <v-col col="sm-3">
              <label>End Date</label>

              <v-menu v-model="fromDateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y
                full-width max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field class="text" v-model="endDate" placeholder="Select End Date" append-icon="event"
                    persistent-hint dense readonly outlined v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="endDate" no-title @input="fromDateMenu1 = false"></v-date-picker>
              </v-menu> </v-col>
          </div>
          <v-row justify="center" align="center">
            <v-checkbox class="pa-2" @click="group('title_wise')" v-model="title_wise" label="Title Wise"></v-checkbox>
            <v-checkbox class="pa-2" @click="group('copy_wise')" v-model="copy_wise" label="Copy Wise"></v-checkbox>
          </v-row>
          </div>
          <div>
            <center>
              <v-btn color="success" class="mb-4" @click="dailyData1">Fetch Data</v-btn>
            </center>
          </div>
        </div>
        <div v-if="loader">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
        <v-data-table v-if="flag" :headers="headers" :search="search" :items="tabledata" class="elevation-5 ma-2">
          <template v-slot:top>
            <v-toolbar flat color="white" align="center" justify="center">
              <v-toolbar-title v-if='flag'>Title Wise Report</v-toolbar-title>
              <v-toolbar-title v-else>Copy Wise Report</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field  v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
              <!-- <download-excel
                class="ma-3"
                v-if="tabledata.length != 0"
                color="primary"
                dark
                :data="tabledata"
                :fields="fields"
                worksheet="My Worksheet"
                name="Title_Wise_Report.xls"
              >
                <v-btn color="success" dark class="mb-2">
                  <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
              </download-excel> -->
              <excelheader v-if="tabledata.length != 0" :headers="headers1" :tabledata="tabledata"
                report_name="Title Wise Report">
              </excelheader>
            </v-toolbar>
          </template>
          <template v-slot:item.date_of_entry="{ item }">
            <span v-if="item.date_of_entry != ''">{{
              item.date_of_entry.split("T")[0].split("-")[2] +
              "-" +
              item.date_of_entry.split("T")[0].split("-")[1] +
              "-" +
              item.date_of_entry.split("T")[0].split("-")[0]
            }}</span><span v-else></span>
          </template>
        </v-data-table>
        <v-data-table v-else :headers="headers1" :search="search" :items="tabledata" class="elevation-5 ma-2">
          <template v-slot:top>
            <v-toolbar flat color="white" align="center" justify="center">
              <v-toolbar-title v-if='flag'>Title Wise Report</v-toolbar-title>
              <v-toolbar-title v-else>Copy Wise Report</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
              <!-- <download-excel
                class="ma-3"
                v-if="tabledata.length != 0"
                color="primary"
                dark
                :data="tabledata"
                :fields="fields1"
                worksheet="My Worksheet"
                name="Copy_Wise_Report.xls"
              >
                <v-btn color="success" dark class="mb-2">
                  <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
              </download-excel> -->
              <excelheader v-if="tabledata.length != 0" :headers="headers1" :tabledata="tabledata"
                report_name="Copy Wise Title Report">
              </excelheader>
            </v-toolbar>
          </template>
          <template v-slot:item.date_of_entry="{ item }">
            {{
              item.date_of_entry.split("T")[0].split("-")[2] +
              "-" +
              item.date_of_entry.split("T")[0].split("-")[1] +
              "-" +
              item.date_of_entry.split("T")[0].split("-")[0]
            }}
          </template>
        </v-data-table>
        </div>
      </div>
    </v-card>
  
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { table2excel } from "../jquery.table2excel";
import xlsx_header from "@/components/common_table_header.vue";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  components: {
    "excelheader": xlsx_header
  },
  data: () => ({
    fields: {
      "Date Of Entry": {
        field: "date_of_entry",
        callback: (item) => {
          return item == ""
            ? ""
            : item.split("T")[0].split("-")[2] +
            "-" +
            item.split("T")[0].split("-")[1] +
            "-" +
            item.split("T")[0].split("-")[0];
        },
      },
      Title: "title",
      Author: "author",
      Department: "dept",
      Publisher: "publisher",

      Price: "price",
      "No Of Copies": "copies",
      "Total Price": "total_price",
    },
    fields1: {
      "Accession No": "acc_no",
      Title: "title",
      "Date Of Entry": {
        field: "date_of_entry",
        callback: (item) => {
          return item == ""
            ? ""
            : item.split("T")[0].split("-")[2] +
            "-" +
            item.split("T")[0].split("-")[1] +
            "-" +
            item.split("T")[0].split("-")[0];
        },
      },

      Author: "author",
      Department: "dept",
      Publisher: "publisher",
      Price: "price",

    },
    dept: null,
    fromDateMenu1: false,
    usertype: null,
    valid: false,
    loader: false,
    userid: "",
    bookitemid: "",
    selected: [],
    errorMsg: {
      roleLinkId: false,
    },
    rules: {
      required: (value) => !!value || "Required.",
    },
    tabledata: [],
    snackbar: false,
    snackbar_msg: "",
    color: "",
    search: "",
    org: "",
    dialog: false,
    fromDateMenu: false,
    fromDateVal: [],
    startDate: "",
    endDate: "",
    dailyData: [],
    series_data: [],
    dept_list: [],
    department: null,
    usertype_data: [],
    minDate: "2020-01-05",
    maxDate: new Date(),
    series: "",
    no_of_days: 90,
    title_wise: true,
    copy_wise: false,
    flag: true,
    init_loading: false,
    loader: false,
    headers: [
      {
        text: "Sr no",
        align: "left",
        sortable: true,
        value: "srno",
      },
      {
        text: "Date Of Entry",
        align: "left",
        sortable: true,
        value: "date_of_entry",
      },
      {
        text: "Title",
        align: "left",
        sortable: true,
        value: "title",
      },
      {
        text: "Editon",
        align: "left",
        sortable: true,
        value: "edition",
      },
      {
        text: "Author",
        align: "left",
        sortable: true,
        value: "author",
      },
      {
        text: "Department",
        align: "left",
        sortable: true,
        value: "dept",
      },
      {
        text: "Publisher",
        align: "left",
        sortable: true,
        value: "publisher",
      },
      {
        text: "Price",
        align: "left",
        sortable: true,
        value: "price",
      },
      {
        text: "No Of Copies",
        align: "left",
        sortable: true,
        value: "copies",
      },
      {
        text: "Total Price",
        align: "left",
        sortable: true,
        value: "total_price",
      },
      {
        text: "Dis Price",
        align: "left",
        sortable: true,
        value: "disprice",
      },
    ],

    headers1: [
      {
        text: "Sr no",
        align: "left",
        sortable: true,
        value: "srno",
      },
      {
        text: "Accession No",
        align: "left",
        sortable: true,
        value: "acc_no",
      },
      {
        text: "Date Of Entry",
        align: "left",
        sortable: true,
        value: "date_of_entry",
      },
      {
        text: "Title",
        align: "left",
        sortable: true,
        value: "title",
      },
      {
        text: "Author",
        align: "left",
        sortable: true,
        value: "author",
      },
      {
        text: "Department",
        align: "left",
        sortable: true,
        value: "dept",
      },
      {
        text: "Publisher",
        align: "left",
        sortable: true,
        value: "publisher",
      },
      {
        text: "Price",
        align: "left",
        sortable: true,
        value: "price",
      },

    ],
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'Report',
        disabled: false,
        href: '/reports',
      },
      {
        text: 'Title Report',
        disabled: true,
        href: '',
      },
    ],
  }),
  computed: {
    fromDateDisp() {
      return this.fromDateVal;
      // format/do something with date
    },
    filteredDesserts() {
      return this.dailyData.filter((d) => {
        return Object.keys(this.filters).every((f) => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },

  mounted() {
    this.startDate = moment(String('1900-01-01')).format("YYYY-MM-DD");

    this.endDate = moment(String(new Date())).format("YYYY-MM-DD");
    this.onLoad();
  },
  methods: {
    group(value) {
      this.tabledata = [];
      if (value == "title_wise") {
        this.copy_wise = false;
        this.flag = true;
      }
      if (value == "copy_wise") {
        this.title_wise = false;
        this.flag = false;
      }
    },
    dailyData1() {
      if (this.startDate != "" && this.endDate != "") {
        this.loader = true;
        const data = {
          firstDate: moment(String(this.startDate)).format("DD-MM-YYYY"),
          lastDate: moment(String(this.endDate)).format("DD-MM-YYYY"),
          series_id: this.series,
          flag: this.flag,
          department: this.department,
        };
        axios
          .post("/Librarian/title_wise_data", data)
          .then((res) => {
            if (res.data.msg == "200") {
              this.loader = false;
              this.tabledata = res.data.data;
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
            window.console.log(error);
            this.loader = false;
          })
          .finally(() => {
            this.loader = false;
          });
      } else {
        this.showSnackbar("#b71c1c", "Please Select Dates");
      }
    },
    onLoad() {
      this.init_loading = true;
      axios
        .post("/Librarian/getseriesData")
        .then((res) => {
          if (res.data.msg == "200") {
            this.init_loading = false;
            this.series_data = res.data.series_data;
            this.series_data.push({ name: "ALL", id: "ALL" });
            this.series = "ALL";
            this.dept_list = res.data.dept_list
            this.dept_list.push({ name: "ALL", id: "ALL" });
            this.department = "ALL";
            this.no_of_days = res.data.no_of_days;
            const today = new Date();
            const previousdate = new Date();
            previousdate.setDate(today.getDate() - 9000);
            // this.startDate = moment(String(previousdate)).format("YYYY-MM-DD");
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        })
        .finally(() => {
          // var loader = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      // this.$refs.form.reset()
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}

.fields {
  padding: 0px !important;
}

.cus-card {
  background: #d3d3d33d;
  margin: 1rem;
  padding: 1rem;
  border: 2px solid gray;
}

.c-label {
  font-weight: bold;
}

.add-section {
  display: block;
  margin: 1rem;
}

.text>>>.v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}

.elevation-1 /deep/ tr {
  white-space: nowrap !important;
}

.d-btn {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem;
}

.checkbox {
  margin: 1rem;
  border: 1px solid;
  background: #d3d3d380;
}

.checkbox .box {
  margin-top: 5px;
}

.checkbox /deep/ .v-input--selection-controls {
  margin-top: 0px !important;
}
</style>
